// @flow

type ReturnTypeGetPrice = {
  amount?: number,
  createdAt: {
    date: string | Date,
  },
  type: string,
  isEstimate?: boolean,
}

type CheckPriceConfig = {
  displayConfiguration?: Object,
  rights?: string[],
  type: string,
  prices: Object[],
}

const TYPES = {
  pending_quoter_marged: 'quoter_marged',
  pending_quoter: 'quoter',
  pending_spot: 'spot',
  pending_spot_enquirer: 'spot_enquirer',
}
const QUOTE_MARGED_PRICE = 'quoter_marged'
const B2C_ADVISED_PRICE = 'b2c_advised'

const shouldDisplayPrice = (configuration: Object, prices?: Object[]): boolean => {
  if (
    (!configuration.show && !configuration?.price_condition) ||
    (prices ?? []).find(element => configuration?.price_condition?.[element.type]?.exist) ||
    ((prices ?? []).find(
      element => configuration?.price_condition?.[element.type]?.exist === false,
    ) &&
      configuration.show)
  ) {
    return false
  }

  return true
}

const checkPriceConfig = ({
  displayConfiguration,
  rights,
  type,
  prices,
}: CheckPriceConfig): boolean => {
  let shouldDisplay = true

  // Default
  const defaultConfiguration = displayConfiguration?.default?.find(element => element.type === type)

  if (typeof defaultConfiguration?.show === 'boolean') {
    shouldDisplay = shouldDisplayPrice(defaultConfiguration, prices)
  }

  // Rights
  const rightsConfiguration = displayConfiguration?.rights ?? []

  rightsConfiguration.forEach(element => {
    if (typeof element.right === 'string' && rights?.includes(element.right) && element.default) {
      const defaultRightConfig = element.default.find(item => item.type === type)

      if (defaultRightConfig && typeof defaultRightConfig.show === 'boolean') {
        shouldDisplay = shouldDisplayPrice(defaultRightConfig, prices)
      }
    }
  })

  return shouldDisplay
}

const getPriceMetadata = (price: Object) => {
  const metadata = {}

  price.metadata.forEach(({ name, value }) => {
    metadata[name] = value
  })

  return metadata
}

export const getPrice = (
  type: string,
  prices?: Object[],
  displayConfiguration?: Object,
  rights?: string[],
): ?ReturnTypeGetPrice => {
  const priceType = TYPES[type] ?? type

  if (!prices) return undefined

  let price = prices && prices.find(element => element.type === type)

  const shouldDisplay = checkPriceConfig({ displayConfiguration, rights, type: priceType, prices })

  // Specific case
  if (priceType === QUOTE_MARGED_PRICE && !shouldDisplay) {
    const b2c = prices.find(p => p.type === B2C_ADVISED_PRICE)
    const mustDisplayB2C = checkPriceConfig({
      displayConfiguration,
      rights,
      type: B2C_ADVISED_PRICE,
      prices,
    })

    if (b2c && mustDisplayB2C) return { ...b2c, isEstimate: true }
  }

  if (!shouldDisplay || !price) return undefined

  if (price.metadata?.length) {
    price = { ...price, metadata: getPriceMetadata(price) }
  }

  return price
}

type ReturnTypeGetPrices = {
  [key: string]: ReturnTypeGetPrice,
}

export const getPrices = (
  types: string[],
  prices?: Object[],
  displayConfiguration?: Object,
  rights?: string[],
): ReturnTypeGetPrices => {
  const data = {}

  types.forEach(type => {
    data[type] = getPrice(type, prices, displayConfiguration, rights)
  })

  return data
}

export const findFacets = (facets: Object[]): { ... } => {
  const facet = {}

  if (facets) {
    facets.forEach(v => {
      facet[v.name] = {}
      v.values?.forEach(value => {
        facet[v.name][value.value] = value.count
      })
    })
  }

  return facet
}
