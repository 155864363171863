// @flow

import React, { useCallback, useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import withStyles from '@material-ui/core/styles/withStyles'
import { withTheme } from '@material-ui/core'
import Helmet from 'react-helmet'
import { hot } from 'react-hot-loader'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import withLifecycle from 'recompose/lifecycle'
import LoadingPage from 'sharyn/components/LoadingPage'
import Notifications from '@autodisol/ads-js/components/Notifications'
import withNavigation from 'sharyn/hocs/with-navigation'
import Button from '@material-ui/core/Button'
import {
  navigation,
  initialNavigation,
  dismissFirstNotification,
  deleteUi,
  setUi,
  notify,
  logUserOut,
} from 'sharyn/redux/actions'
import { purgeCache } from 'utils/cache'
import * as Sentry from '@sentry/browser'
import findMatch from 'sharyn/shared/find-match'
import getPageInfo from 'sharyn/shared/get-page-info'
import cond from 'sharyn/util/cond'
import axios from 'axios'
import { mergeUserData, setMode } from '_client/redux/actions'
import BottomNav from 'app/cmp/BottomNav'
import Nav from 'app/cmp/Nav'
import allRoutes from 'app/all-routes'
import { useTranslation } from 'react-i18next'
import { getMyProfileAndMyGroupsQuery } from 'auth/auth-queries'
import { getModesData, getAccessMode } from 'utils/modes'
import Modal from 'Modal'
import { withRouter } from 'react-router-dom'
import { HOME_PATH } from 'home/home-paths'
import { IDENTIFY_PATH } from 'quote/quote-paths'

import type { Mode } from 'types/modes'

import UpdateIcon from '../../public/img/update_black.svg'

let isRouterListening

// Unfortunately, AppJSX is completely re-mounted at each navigation, so we
// cannot use useEffect(() => {}, []) to trigger the refresh only on first load.
// Using a variable instead.
let hasRefreshedUserAndGroupsOnLoad = false
let hasSetSentryData = false

const AppJSX = ({
  classes: css,
  location,
  history: routerHistory,
  pageState,
  isLoggedIn,
  isPageLoading,
  notifications,
  handleDismissNotification,
  isServerRender,
  appName,
  appHost,
  appIcons,
  appManifest,
  appBrowserconfig,
  appShortName,
  apiUrl,
  appVersion,
  isDevEnv,
  dispatch,
  mustBeDisconnected,
  user,
  mode,
  history,
}: {
  classes: Object,
  location: Object,
  history: Object,
  pageState: Object,
  isLoggedIn: boolean,
  isPageLoading?: boolean,
  notifications?: Object[],
  handleDismissNotification: Function,
  isServerRender?: boolean,
  appName: string,
  appHost: string,
  appIcons?: Object[],
  appManifest: string,
  appBrowserconfig: string,
  appShortName?: string,
  apiUrl: string,
  appVersion: string,
  isDevEnv: boolean,
  dispatch: Function,
  mustBeDisconnected: boolean,
  user: Object,
  mode: Mode,
  history: Object,
}) => {
  const { t } = useTranslation()
  const { open, openModal, closeModal } = Modal.useModal()

  const userRef = useRef({ data: user })
  const modeRef = useRef(mode)

  const { match, route: activeRoute } = findMatch(allRoutes, location.pathname, isLoggedIn)
  const { title, backNav } = getPageInfo(activeRoute, pageState, match)
  const titleRequiresData = activeRoute.title instanceof Function
  const { pageComponent: PageComponent, ...route } = activeRoute
  const [getMyProfileAndMyGroups, { loading: isLoadingFetchMyProfile }] = useLazyQuery(
    getMyProfileAndMyGroupsQuery,
  )

  useEffect(() => {
    if (isLoggedIn && !hasSetSentryData) {
      hasSetSentryData = true

      Sentry.setUser({ id: user.id, username: user.username })
      Sentry.setContext('user group', { id: user.currentGroup.id, name: user.currentGroup.name })
    }
  }, [isLoggedIn, user.id, user.username, user.currentGroup?.id, user.currentGroup?.name])

  useEffect(() => {
    if (mustBeDisconnected) {
      Sentry.configureScope(scope => {
        scope.clear()
      })

      dispatch(logUserOut())
    }
  }, [dispatch, mustBeDisconnected])

  const handleUpdateAppVersion = async () => {
    await purgeCache()
    window.location.reload(true)
  }

  const refreshUserAndGroups = async (currentMode?: Mode) => {
    if (!isLoggedIn) return

    const myProfileAndMyGroups = await getMyProfileAndMyGroups()
    const myProfile = myProfileAndMyGroups.data.get_my_profile
    const accessModes = getAccessMode({ data: myProfile })
    const PATHS_NO_NEEDING_RETURN_TO_DASHBOARD = [IDENTIFY_PATH, HOME_PATH]
    const displayParameter = myProfile?.appSettings?.provider?.displayParameter

    if (myProfileAndMyGroups?.data) {
      dispatch(
        mergeUserData({
          ...myProfile,
          ...(displayParameter && { displayParameter }),
          groups: myProfileAndMyGroups.data.get_my_groups,
        }),
      )
    }

    if (currentMode && accessModes.includes(currentMode)) {
      closeModal()
    }

    if (currentMode && !accessModes.includes(currentMode)) {
      if (PATHS_NO_NEEDING_RETURN_TO_DASHBOARD.includes(location.pathname)) {
        const newMode = accessModes.includes(currentMode) ? currentMode : accessModes[0]

        dispatch(setMode(newMode))
        return
      }

      openModal()
    }
  }

  const handleRefresh = useCallback(
    (currentMode?: Mode) => {
      ;(async () => {
        try {
          const { data } = await axios.get('/static/app-version.json')

          if (!isDevEnv && data?.version !== appVersion) {
            // eslint-disable-next-line no-alert
            dispatch(
              notify({
                message: t('alert.updateAppVersion.title'),
                autoHideDuration: null,
                dismissOnMainAction: false,
                icon: <UpdateIcon style={{ width: '2rem', fill: 'white' }} />,
                mainAction: (
                  <Button type="button" color="primary" onClick={handleUpdateAppVersion}>
                    {t('alert.updateAppVersion.content')}
                  </Button>
                ),
              }),
            )
            return
          }
        } catch (err) {
          throw Error('Application version could not be retrieved from the server')
        }

        if (isLoggedIn) {
          refreshUserAndGroups(currentMode)
        }
      })()
    },
    // eslint-disable-next-line
    [appVersion, dispatch, isDevEnv, isLoggedIn, t],
  )

  const handleRefreshUserAndGroups = useCallback(
    () => isLoggedIn && refreshUserAndGroups(),
    // eslint-disable-next-line
    [isLoggedIn],
  )

  useEffect(() => {
    if (!hasRefreshedUserAndGroupsOnLoad) {
      handleRefresh()
      hasRefreshedUserAndGroupsOnLoad = true
    }
  }, [handleRefresh, handleRefreshUserAndGroups])

  useEffect(() => {
    const refresh = () => handleRefresh(mode)

    window.addEventListener('focus', refresh)
    return () => {
      window.removeEventListener('focus', refresh)
    }
  })

  useEffect(() => {
    const currentMode = modeRef.current
    const accessModes = getAccessMode(userRef.current)
    const newMode = accessModes.includes(currentMode) ? currentMode : accessModes[0]

    if (currentMode && !accessModes.includes(currentMode)) {
      dispatch(setMode(newMode))
    }
  }, [dispatch])

  const titleTemplate = () => {
    if (!appName) {
      if (window.location.hostname === appHost) return '%s | ADS'
      return '%s | Provider'
    }
    return `%s | ${appName}`
  }

  const handleReturnToDashboard = () => {
    history.push({ pathname: HOME_PATH })
    closeModal()
  }

  return (
    <>
      <Helmet titleTemplate={titleTemplate()}>
        <html lang="fr" />
        <title>
          {cond([[isPageLoading && titleRequiresData, 'Chargement...'], [isServerRender]], title)}
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta
          name="msapplication-config"
          content={
            window.location.hostname === appHost ? appBrowserconfig : `${apiUrl}${appBrowserconfig}`
          }
        />
        <meta name="apple-mobile-web-app-title" content={appShortName} />
        <link
          key="manifest"
          rel="manifest"
          href={window.location.hostname === appHost ? appManifest : `${apiUrl}${appManifest}`}
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-status-bar-style" content="default" />

        <link href="https://fonts.googleapis.com/css?family=Poppins:500" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Lato:400,700,900" rel="stylesheet" />
      </Helmet>
      {/* Fragment not supported (https://github.com/nfl/react-helmet/issues/342) */}
      {appIcons &&
        appIcons.map(i => (
          <Helmet key={i.content}>
            <link
              rel="apple-touch-icon"
              sizes={`${i?.width}x${i?.height}`}
              href={i && i?.content}
            />
            <link
              rel="icon"
              type="image/png"
              sizes={`${i?.width}x${i?.height}`}
              href={i && i?.content}
            />
            <link
              rel="mask-icon"
              sizes={`${i.width}x${i.height}`}
              href={i && i?.content}
              color="#ffffff"
            />
            <link rel="shortcut icon" sizes={`${i?.width}x${i?.height}`} href={i && i?.content} />
          </Helmet>
        ))}
      {isServerRender ? (
        <LoadingPage noDelay />
      ) : (
        <>
          {isLoggedIn && (
            <Nav
              {...{ backNav }}
              title={isPageLoading && titleRequiresData ? '' : title}
              isLoadingRefrechUserAndGroups={isLoadingFetchMyProfile}
              className={css.hideOnPrint}
            />
          )}
          <PageComponent {...{ route, match, routerHistory }} />
          <Notifications
            {...{ notifications, handleDismissNotification }}
            className={css.hideOnPrint}
          />
          {activeRoute.withBottomNav && <BottomNav {...{ routerHistory }} />}

          {open && (
            <Modal open>
              <Modal.Content>{t('globals.groupHasChanged')}</Modal.Content>

              <Modal.Actions>
                <div className={css.modal__actions}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    style={{ width: '50%', marginTop: 10 }}
                    onClick={handleReturnToDashboard}
                  >
                    {t('globals.returnToDashoard')}
                  </Button>
                </div>
              </Modal.Actions>
            </Modal>
          )}
        </>
      )}
    </>
  )
}

const App: any = compose(
  hot(module),
  withStyles(() => ({
    // Use @global to define fontFamily and fontSize for the whole app
    '@global': {
      html: { background: '#FFFFFF' },
      body: { fontFamily: 'Lato,Roboto,sans-serif', fontSize: 16, background: '#FFFFFF' },
    },
    hideOnPrint: { '@media print': { display: 'none' } },
    modal__actions: {
      display: 'flex',
      justifyContent: 'center',
    },
  })),
  withRouter,
  withRedux(
    ({ pageData, user, env, asyncMap, ui, router }) => {
      const { currentMode } = getModesData(user, router.location.pathname ?? '')

      return {
        pageState: { pageData, user, env, router },
        isLoggedIn:
          !!user &&
          !(
            user.data?.customTheme &&
            // $FlowIgnore[method-unbinding]
            Object.prototype.hasOwnProperty.call(user.data.customTheme, 'primary') &&
            // $FlowIgnore[method-unbinding]
            !Object.prototype.hasOwnProperty.call(user.data.customTheme, 'palette')
          ),
        /* eslint-disable */
        appName: user?.data.config.user?.theme?.app_names?.provider?.name,
        appIcons: ui?.icons?.app_icons,
        appShortName: ui?.icons?.app_names?.provider?.short_name,
        /* eslint-enable  */
        icons: ui?.icons,
        apiUrl: env.API_URL,
        appManifest: ui?.icons?.manifest ?? '',
        appBrowserconfig: ui?.icons?.browserconfig ?? '',
        isPageLoading: asyncMap.page,
        notifications: ui.notifications,
        isServerRender: env.isServerRender,
        appHost: env.APP_ADS_HOST,
        appVersion: env.APP_VERSION,
        isDevEnv: env.IS_DEV_ENV,
        mustBeDisconnected: Boolean(
          user &&
            !user.data.config.quote.quote.standard &&
            !user.data.config.quote.quote.resval &&
            !user.data.config.quote.quote.standard_bike,
        ),
        user: user?.data ?? {},
        mode: currentMode,
      }
    },
    { handleDismissNotification: dismissFirstNotification },
  ),
  withNavigation(navigation, initialNavigation),
  withLifecycle({
    componentWillMount() {
      if (!isRouterListening) {
        // $FlowIgnore[object-this-reference]
        this.props.history.listen(() => {
          // $FlowIgnore[object-this-reference]
          this.props.dispatch(deleteUi('subtitle'))
          // $FlowIgnore[object-this-reference]
          if (!this.props.history.location.pathname.match(/^\/quote\/|^\/search|^\/edit-quote/)) {
            // $FlowIgnore[object-this-reference]
            this.props.dispatch(deleteUi('previousSearchFilters'))
            // $FlowIgnore[object-this-reference]
            this.props.dispatch(deleteUi('previousIndeterminateGroups'))
          }
        })
        isRouterListening = true
      }
      // $FlowIgnore[object-this-reference]
      if (!this.props.appIcons) {
        // $FlowIgnore[object-this-reference]
        if (window.location.hostname === this.props.appHost) {
          // $FlowIgnore[object-this-reference]
          const linkPath = window.location.hostname === this.props.appHost ? 'ads' : 'neutral'
          // $FlowIgnore[object-this-reference]
          this.props.dispatch(
            setUi({
              icons: {
                app_icons: [
                  {
                    content: `/static/${linkPath}/img/favicon/favicon-256x256.png`,
                    width: '256',
                    height: '256',
                    type: 'png',
                  },
                ],
                manifest: `/static/${linkPath}/site.webmanifest`,
                browserconfig: `/static/${linkPath}/img/favicon/browserconfig.xml`,
              },
            }),
          )
        } else {
          // $FlowIgnore[object-this-reference]
          axios.get(`${this.props.pageState.env.API_URL}/theme/get-config`).then(response => {
            // $FlowIgnore[object-this-reference]
            this.props.dispatch(setUi({ icons: response.data }))
          })
        }
      }
    },
  }),
  withTheme(),
)(AppJSX)

export default App
