// @flow

import React from 'react'
import moment from 'moment'
import compose from 'recompose/compose'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useMutation } from '@apollo/client'
import { connect as withRedux } from 'react-redux'
import { useQuoteDataFromCache } from 'hooks/useQuoteDataFromCache'
import TextField from '@autodisol/ads-js/components/Inputs/ReactHookForm/TextField'
import Modal from 'Modal'
import ActionWithModal from 'quote/cmp/QuoteActionsPanel/Resval/ActionWithModal'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import {
  MIN_KM,
  MAX_KM,
  MIN_CONTRACT_DURATION,
  MAX_CONTRACT_DURATION,
} from 'quote/cmp/validationRules'
import { validateStringNumber } from 'utils/reactHookForm'
import { updateFleetQuoteQuery, getQuoteAndCountQuery } from 'quote/quote-queries'
import i18next from 'services/translations/config'
import { RIGHT_UPDATE_FLEET_QUOTE } from '_client/config'
import { CURRENT_DATE } from 'utils/constants/date'
import { getDiffBetweenTwoDate } from 'utils/date'

import MileageIcon from '../../../../../public/img/mileageIcon.inline.svg'

const styles = () => ({
  info: {
    fontWeight: 600,
    marginBottom: 20,
    '& > svg:nth-child(2)': {
      marginBottom: 0,
    },
    '& > span': {
      fontWeight: 900,
    },
  },
  icon: {
    marginRight: 5,
    width: 20,
    marginBottom: '-7px',
  },
  mileageIcon: {
    marginRight: 5,
    width: 20,
    marginBottom: '-3px',
  },
})

const FORM_ID = 'updateTheRequest'

const validationSchema = z.object({
  kmToStart: validateStringNumber({
    invalidValue: i18next.t('quote.updateTheRequest.form.error.kmToStart.invalidValue'),
    message: i18next.t('quote.updateTheRequest.form.error.kmToStart.message'),
    min: MIN_KM,
    max: MAX_KM,
  }),
  kmToGo: validateStringNumber({
    invalidValue: i18next.t('quote.updateTheRequest.form.error.kmToGo.invalidValue'),
    message: i18next.t('quote.updateTheRequest.form.error.kmToGo.message'),
    min: MIN_KM,
    max: MAX_KM,
  }),
  remainingContractDuration: validateStringNumber({
    invalidValue: i18next.t(
      'quote.updateTheRequest.form.error.remainingContractDuration.invalidValue',
    ),
    message: i18next.t('quote.updateTheRequest.form.error.remainingContractDuration.message'),
    min: MIN_CONTRACT_DURATION,
    max: MAX_CONTRACT_DURATION,
  }),
  feedbackUpdateTheRequest: z.string().nullable(),
})

type ValidationSchema = z.infer<typeof validationSchema>

const useUpdateTheRequest = ({ id, closeModal }) => {
  const [updateFleetQuote, { loading: isLoading }] = useMutation(updateFleetQuoteQuery, {
    onCompleted: () => {
      closeModal()
    },
    refetchQueries: [getQuoteAndCountQuery],
  })

  const onSubmit = data => {
    updateFleetQuote({
      variables: {
        id,
        pendingUpdate: {
          contractKmStart: data.kmToStart,
          contractKm: data.kmToGo,
          contractDuration: data.remainingContractDuration,
        },
        comment: data.feedbackUpdateTheRequest,
      },
    })
  }

  return {
    onSubmit,
    isLoading,
  }
}

const getNumberOfMonthsRemaining = (contractDateStart, contractDuration) => {
  const contractEndDate = moment(contractDateStart, 'YYYY-MM-DD')
    .add(contractDuration, 'month')
    .format('YYYY-MM-DD')

  const diffBetweenTwoDate = getDiffBetweenTwoDate({
    smallestDate: CURRENT_DATE,
    biggestDate: contractEndDate,
    measurement: 'months',
    hasfloatingPointNumber: true,
  })

  return Math.ceil(diffBetweenTwoDate)
}

type Props = {
  id: string,
  rights: string[],
  classes: Object,
}

const UpdateTheRequestAction = ({ id, rights, classes }: Props) => {
  const { t } = useTranslation()
  const { open, openModal, closeModal } = Modal.useModal()
  const quoteData = useQuoteDataFromCache(id)
  const { onSubmit, isLoading } = useUpdateTheRequest({ id, closeModal })
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    reValidateMode: 'onSubmit',
  })

  const { contractDateStart, contractKmStart, contractKm, contractDuration } = quoteData
  const hasRightToUpdateFleetQuote = rights.includes(RIGHT_UPDATE_FLEET_QUOTE)
  const numberOfMonthsRemaining = getNumberOfMonthsRemaining(
    contractDateStart.date,
    contractDuration,
  )

  const handleCloseModal = () => {
    closeModal()
    reset()
  }

  if (!hasRightToUpdateFleetQuote) return null

  return (
    <>
      <ActionWithModal
        handleCloseModal={handleCloseModal}
        actionButtonContent={t('quote.updateTheRequest.title')}
        ariaLabelledby="update the request"
        ariaDescribedby="update the request"
        modalTitle={t('quote.updateTheRequest.title')}
        modalDescription={t('quote.updateTheRequest.description')}
        errors={errors}
        isLoading={isLoading}
        modalValidateButtonContent={t('quote.updateTheRequest.title')}
        open={open}
        openModal={openModal}
        formId={FORM_ID}
      >
        <form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          <p className={classes.info}>
            <LocalShippingIcon className={classes.icon} />{' '}
            {t('quote.updateTheRequest.form.fields.helperText.kmToStart')}{' '}
            {contractKmStart ? <span>{`${contractKmStart} ${t('globals.km')}`}</span> : ''}
          </p>
          <TextField
            control={control}
            name="kmToStart"
            defaultValue=""
            type="number"
            label={`${t('quote.kmToStart')} *`}
            placeholder={`${t('globals.exampleColon')} 1000`}
          />

          <p className={classes.info}>
            <MileageIcon className={classes.mileageIcon} />{' '}
            {t('quote.updateTheRequest.form.fields.helperText.kmToGo')}{' '}
            {contractKm ? <span>{`${contractKm} ${t('globals.km')}`}</span> : ''}
          </p>
          <TextField
            control={control}
            name="kmToGo"
            defaultValue=""
            type="number"
            label={`${t('quote.kmToGo')} *`}
            placeholder={`${t('globals.exampleColon')} 1000`}
          />

          <p className={classes.info}>
            <QueryBuilderIcon className={classes.icon} />{' '}
            {t('quote.updateTheRequest.form.fields.helperText.currentRemainingContractDuration')}{' '}
            {numberOfMonthsRemaining ? (
              <span>{`${numberOfMonthsRemaining} ${t('globals.month')}`}</span>
            ) : (
              ''
            )}
          </p>
          <TextField
            control={control}
            name="remainingContractDuration"
            defaultValue=""
            type="number"
            label={`${t('quote.updateTheRequest.form.fields.remainingContractDuration')} *`}
            placeholder={`${t('globals.exampleColon')} 36`}
          />

          <TextField
            variant="standard"
            control={control}
            name="feedbackUpdateTheRequest"
            defaultValue=""
            type="text"
            label={t('quote.updateTheRequest.form.fields.feedback')}
            placeholder={t('quote.generalFeedbackPlaceholder')}
            multiline
            rows={3}
          />
        </form>
      </ActionWithModal>
    </>
  )
}

export default (compose(
  withRedux(({ user }) => ({
    rights: user.data.rights,
  })),
  withStyles(styles),
)(UpdateTheRequestAction): any)
