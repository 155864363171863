// @flow

import React from 'react'
import { fade } from '@jiaminghi/color'
import * as Sentry from '@sentry/browser'
import LoginPageJSX from '@autodisol/ads-js/components/LoginPage'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import withHandlers from 'recompose/withHandlers'
import withProps from 'recompose/withProps'
import formData from 'sharyn/client/form-data'
import { asyncRequest, asyncFailure, logUserIn, notify } from 'sharyn/redux/actions'
import { apolloClient } from '_client/client'
import call from 'sharyn/shared/call'
import { withTranslation } from 'react-i18next'
import {
  updateUserGroupList,
  setCustomTheme,
  updateDisplayParameter,
  updateExportSelection,
} from '_client/redux/actions'
import {
  networkErrorNotification,
  invalidCredentialsNotification,
  genericLoginErrorNotification,
} from 'app/notifications'
import { getGroupsQuery } from 'auth/auth-queries'
import { SEARCH_FIELDS, EXPORT_FIELDS } from 'auth/constants'

type Props = {|
  appHost?: string,
  appVersion?: string,
  helpInfo?: string,
|}

const LoginPageCmp = ({ appHost, appVersion, helpInfo, ...rest }: Props) => (
  <LoginPageJSX
    style={{ marginTop: 100 }}
    isNeutral={window.location.hostname !== appHost}
    {...{ appVersion, helpInfo }}
    {...rest}
  />
)

const LoginPage: any = withTranslation()(
  compose(
    withRedux(({ asyncMap, env, user, ui }) => ({
      isLoading: asyncMap.login,
      urlBase: env.API_URL,
      appHost: env.APP_ADS_HOST,
      appVersion: env.APP_VERSION,
      presetDisplayFields: user?.data?.displayParameter,
      exportSelection: user?.data?.exportSelection,
      helpInfo: ui?.icons?.help,
    })),
    withProps(props => {
      const { i18n } = props

      if (window.location.hostname !== props.appHost) {
        return {
          title: i18n.t('auth.welcome'),
          subtitle: i18n.t('auth.subtitleLoginNeutralPage'),
          message: i18n.t('auth.messageLoginNeutralPage'),
          head: i18n.t('auth.enquirerLoginPage'),
        }
      }
      return {
        title: i18n.t('auth.title'),
        subtitle: '',
        message: '',
        head: '',
        imgUrl: '/static/img/logoPictureAndText.svg',
      }
    }),
    withHandlers({
      onSubmit:
        ({ urlBase, dispatch, presetDisplayFields, exportSelection }) =>
        async e => {
          e.preventDefault()

          const { username, password } = formData(e)
          const loginFormData = new FormData()
          loginFormData.append('_username', username)
          loginFormData.append('_password', password)
          dispatch(asyncRequest('login'))
          let loginData
          try {
            loginData = (
              await call({
                urlBase,
                urlPath: '/login_check',
                body: loginFormData,
                withCredentials: true,
              })
            ).data
          } catch (err) {
            dispatch(asyncFailure({ asyncKey: 'login' }))
            if (err.response?.status === 401) {
              dispatch(notify(invalidCredentialsNotification))
            } else if (err.message === 'Network Error') {
              dispatch(notify(networkErrorNotification))
            } else {
              dispatch(notify(genericLoginErrorNotification))
              throw err
            }
          }
          if (!loginData) {
            return
          }

          dispatch(logUserIn({ jwt: loginData.token, data: loginData.user }))

          Sentry.setUser({ id: loginData.user.id, username: loginData.user.username })
          Sentry.setContext('user group', {
            id: loginData.user.currentGroup.id,
            name: loginData.user.currentGroup.name,
          })

          const groups = await apolloClient
            .query({
              query: getGroupsQuery,
            })
            .then(r => r.data)
          dispatch(updateUserGroupList(groups.get_my_groups))

          const displayParameter =
            loginData?.user?.appSettings?.provider?.displayParameter ?? SEARCH_FIELDS

          if (!presetDisplayFields) dispatch(updateDisplayParameter(displayParameter))

          if (!exportSelection) dispatch(updateExportSelection(EXPORT_FIELDS))

          const themingColor = loginData?.user.config.user?.theme?.colors
          if (themingColor) {
            const theming = {
              palette: {
                text: {
                  secondary: themingColor.primary_color_text,
                },
                primary: {
                  main: themingColor.primary_color_button_background,
                  dark: themingColor.primary_color_button_background_hover,
                  contrastText: themingColor.primary_color_button_text,
                },
                secondary: {
                  main: themingColor.secondary_color_button_background_hover,
                  dark: themingColor.secondary_color_button_background,
                },
              },
              overrides: {
                MuiBottomNavigationAction: {
                  root: {
                    '&$selected': {
                      '&>$wrapper': {
                        color: themingColor.primary_color_text,
                      },
                    },
                  },
                },
                MuiTableRow: {
                  root: {
                    '&:nth-of-type(odd)': {
                      '&:hover': {
                        backgroundColor: fade(themingColor.primary_color_button_background, 10),
                      },
                    },
                    '&:nth-of-type(even)': {
                      backgroundColor: '#F5F5F5',
                      '&:hover': {
                        backgroundColor: fade(themingColor.primary_color_button_background, 10),
                      },
                    },
                  },
                },
              },
            }

            dispatch(setCustomTheme(theming))
          }
        },
    }),
  )(LoginPageCmp),
)

export default LoginPage
