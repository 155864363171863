// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import withStyles from '@material-ui/core/styles/withStyles'
import numeral from 'numeral'
import Typography from '@material-ui/core/Typography'
import Divider from 'quote/cmp/QuoteActionsPanel/Divider'
import {
  QUOTE_STATUS_CLOSED,
  QUOTE_STATUS_UPDATED_FLEET,
  QUOTE_STATUS_ASSIGNED,
  QUOTE_STATUS_KEPT,
  QUOTE_STATUS_REJECTED_ASSIGNMENT,
  QUOTE_STATUS_SENT,
  QUOTE_STATUS_WAIT_ASSIGN_VALID,
} from '_client/config'
import { getPrices } from '_client/util'
import PriceForUpdateFleetStatus from 'quote/cmp/QuoteActionsPanel/Resval/Price/PriceForUpdateFleetStatus'
import Title from 'quote/cmp/QuoteActionsPanel/Resval/Price/Title'
import QuoterPriceNotMarged from 'quote/cmp/QuoteActionsPanel/QuoterPriceNotMarged'
import { useQuoteDataFromCache } from 'hooks/useQuoteDataFromCache'
import Tooltip from 'Tooltip'
import { NO_PRICE } from 'utils/constants'

const NET_OFFER_STATUSES = [
  QUOTE_STATUS_ASSIGNED,
  QUOTE_STATUS_SENT,
  QUOTE_STATUS_KEPT,
  QUOTE_STATUS_WAIT_ASSIGN_VALID,
  QUOTE_STATUS_REJECTED_ASSIGNMENT,
  QUOTE_STATUS_CLOSED,
]

const styles = () => ({
  title: {
    fontWeight: 700,
    color: '#868F98',
    margin: '0.5rem 0 0',
    textAlign: 'center',
  },
  price: {
    textAlign: 'center',
    fontWeight: 900,
    '& > div > div': {
      marginTop: '18px !important',
    },
  },
  price__comment: {
    textAlign: 'center',
  },
  element: {
    fontSize: '1.125rem',
    textAlign: 'center',
    fontWeight: 900,
    '& > span': {
      color: '#868F98',
      fontWeight: 700,
      paddingRight: 5,
    },
  },
})

const usePrice = ({ id, user, sendInFleetDelay }) => {
  const quoteData = useQuoteDataFromCache(id)

  const {
    quoter_marged: quoterPrice,
    pending_quoter_marged: pendingQuoterPrice,
    damage: damagePrice,
    mechanical: mechanicalPrice,
  } = getPrices(
    ['quoter_marged', 'pending_quoter_marged', 'damage', 'mechanical'],
    quoteData?.prices,
    user?.config?.pricing?.display,
    user?.rights,
  )

  return {
    quoterPrice: quoterPrice?.amount,
    pendingQuoterPrice: pendingQuoterPrice?.amount,
    damagePrice: damagePrice?.amount ?? 0,
    mechanicalPrice: mechanicalPrice?.amount ?? 0,
    contractKm: quoteData?.contractKm,
    contractKmStart: quoteData?.contractKmStart,
    contractDuration: quoteData?.contractDuration,
    status: quoteData?.status,
    offerDeadline: moment(quoteData?.contractDateStart?.date)
      .add(sendInFleetDelay, 'days')
      .format('DD/MM/YYYY'),
  }
}

type Props = {
  classes: Object,
  id: string,
  comment?: string,
  user?: Object,
  quoterPriceNotMarged?: Object,
  sendInFleetDelay: number,
}

const Price = ({ classes, id, comment, user, quoterPriceNotMarged, sendInFleetDelay }: Props) => {
  const { t } = useTranslation()
  const data = usePrice({ id, user, sendInFleetDelay })

  const { status, offerDeadline, quoterPrice, damagePrice, mechanicalPrice } = data
  const isUpdatedFleetStatus = status === QUOTE_STATUS_UPDATED_FLEET
  const netOffer = quoterPrice ? quoterPrice - damagePrice - mechanicalPrice : 0

  return (
    <>
      {!NET_OFFER_STATUSES.includes(status) && (
        <>
          <Title
            title={
              isUpdatedFleetStatus
                ? `${t('globals.newRequest')} `
                : `${t('quote.residualValueOffering')} `
            }
            offerDeadline={offerDeadline}
            offset={isUpdatedFleetStatus ? '0px -80px' : '0px -105px'}
          />
          <Divider />
        </>
      )}
      {isUpdatedFleetStatus && <PriceForUpdateFleetStatus {...data} />}
      {NET_OFFER_STATUSES.includes(status) && (
        <>
          <p className={classes.element}>
            <span>{t('quote.prices.residualValueOffering')}</span>{' '}
            {quoterPrice ? `${numeral(quoterPrice).format()} €` : NO_PRICE}
          </p>
          <p className={classes.element}>
            <span>{t('quote.prices.netOffer')}</span>{' '}
            {quoterPrice ? `${numeral(netOffer).format()} €` : NO_PRICE}
            <Tooltip title={t('quote.prices.offerAfterDeductionDamageCosts')} offset="0px -80px" />
          </p>
          <p className={classes.element}>
            <span>{t('quote.prices.damageCost')}</span> {` ${numeral(damagePrice).format()} €`}
          </p>
          <p className={classes.element}>
            <span>{t('quote.prices.mechanicalCost')}</span>{' '}
            {` ${numeral(mechanicalPrice).format()} €`}
          </p>
        </>
      )}
      {![...NET_OFFER_STATUSES, QUOTE_STATUS_UPDATED_FLEET].includes(status) && (
        <div className={classes.price}>
          {quoterPrice ? `${numeral(quoterPrice).format()} €` : '- €'}
        </div>
      )}

      {![QUOTE_STATUS_UPDATED_FLEET, QUOTE_STATUS_CLOSED].includes(status) && (
        <div className={classes.price}>
          <QuoterPriceNotMarged
            quoterPrice={quoterPriceNotMarged}
            rights={user?.rights}
            style={{ marginTop: 24 }}
          />
        </div>
      )}

      {comment && (
        <>
          <Divider />
          <Typography variant="h2" className={classes.title}>
            {t('globals.comment')}
          </Typography>
          <p className={classes.price__comment}>{comment}</p>
        </>
      )}
    </>
  )
}

export default (compose(
  withRedux(({ user }) => ({
    user: user.data,
    sendInFleetDelay: user.data.config.quote.quote.resval.send_in_fleet_delay,
  })),
  withStyles(styles),
)(Price): any)
