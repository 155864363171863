// @flow
import React, { useState, useEffect } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import CheckCircle from '@material-ui/icons/CheckCircle'
import CommentIcon from '@material-ui/icons/Comment'
import DetailsIcon from '@material-ui/icons/LocalOffer'
import HistoryIcon from '@material-ui/icons/History'
import Edit from '@material-ui/icons/Edit'
import NavigateBefore from '@material-ui/icons/NavigateBefore'
import PrintIcon from '@material-ui/icons/Print'
import RoomIcon from '@material-ui/icons/Room'
import TabsMenuIcon from '@material-ui/icons/MoreVert'
import Alert from '@autodisol/ads-js/components/Alert'
import Comments from '@autodisol/ads-js/components/Comments/Comments'
import ModifyQuote from '@autodisol/ads-js/components/ModifyQuoteCmp/ModifyQuote'
import classnames from 'classnames'
import moment from 'moment'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import LoadingPage from 'sharyn/components/LoadingPage'
import withFields from 'sharyn/hocs/with-fields'
import { useTranslation } from 'react-i18next'
import HistoryLog from 'quote/cmp/HistoryLog'
import Quotation from 'quote/cmp/VehicleSheet/Quotation'
import Traceability from 'quote/cmp/Traceability'
import UpdateSiteQuoteModal from 'quote/cmp/UpdateSiteQuote'
import { printQuotePath } from 'quote/quote-paths'
import {
  getCommentsQuery,
  adminExpiredQuoteApollo,
  adminTagsQuoteMutation,
  adminPriceQuoteMutation,
  listSitesQueryApollo,
  adminSiteQuoteMutation,
  listGroupQuery,
  listUsersQuery,
  adminMoveQuoteMutation,
  getQuoteAndCountQuery,
  getQuoteAutoriginQuery,
} from 'quote/quote-queries'
import { notify } from 'sharyn/redux/actions'
import {
  TAG_IS_DAMAGED,
  TAG_HAS_VAT,
  TAG_IS_BROKEN,
  TAG_NEEDS_TIMING_BELT,
  TAG_HAS_SERVICE_RECORD,
  TAG_HAS_NO_VAT,
  TAG_IS_FOREIGN,
  TAG_PREVIOUS_AMBULANCE,
  TAG_PREVIOUS_TAXI,
  TAG_PREVIOUS_DRIVING_SCHOOL,
  TAG_PREVIOUS_LSR_LTR,
  TAG_IS_TESTED,
  TAG_IS_NOT_TESTED,
  TAG_STATE_BAD,
  TAG_STATE_AVERAGE,
  TAG_STATE_GOOD,
  TAG_STATE_VERY_GOOD,
  QUOTE_STATUS_UPDATED_FLEET,
  TAG_QUOTE_IS_ORG_MULTIQUOTED,
  QUOTE_STATUS_DRAFT,
  QUOTE_STATUS_CHANGES_REQUESTED,
  QUOTE_STATUS_PROCESSING,
  QUOTE_STATUS_ONHOLD_OPENING,
  QUOTE_STATUS_PENDING_PRICE,
  QUOTE_STATUS_WAITING,
} from '_client/config'
import questions from 'quote/cmp/questionListAdminQuote'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { getPrice, getPrices } from '_client/util'
import i18next from 'services/translations/config'
import { getModesData, getAccessMode } from 'utils/modes'
import { MODES } from 'utils/constants/modes'
import { DOCUMENT_TYPE } from 'utils/constants/quote'
import { getNameSchema } from 'quote/cmp/findSchema'
import { setMode } from '_client/redux/actions'
import { Divider, Radio, RadioGroup } from '@material-ui/core'
import { PRINT_MODE } from 'quote/cmp/PrintQuote/constants'
import Multiquotation from 'quote/cmp/Multiquotation'
import MuiBadge from '@material-ui/core/Badge'
import { useMultiquotation } from 'quote/cmp/Multiquotation/useMultiquotation'

import type { Mode } from 'types/modes'
import MultiquotationIcon from '../../../public/img/multicotation.inline.svg'

const getExcludedElements = (indexedQuote: Object) => {
  const excludedElements = []
  const batteryNumber = indexedQuote?.vehicle?.batteryNumber ?? null
  const electricEngineNumber = indexedQuote?.vehicle?.electricEngineNumber ?? null
  const batteryCapacityKwh = indexedQuote?.vehicle?.batteryCapacityKwh ?? null
  const batteryWarranty = indexedQuote?.vehicle?.batteryWarranty ?? null

  if (
    indexedQuote?.identificationCountry === 'es' ||
    indexedQuote?.identificationCountry === 'be'
  ) {
    excludedElements.push('lastRegDate', 'gears')
  }

  if (batteryNumber === null && batteryCapacityKwh === null && batteryWarranty === null) {
    excludedElements.push('batteryGroupedInformations')
  }

  if (electricEngineNumber === null) {
    excludedElements.push('electricEngineNumber')
  }

  return excludedElements
}

const closeTabsMenu =
  ({ setTabMenuAnchor }) =>
  () => {
    setTabMenuAnchor(null)
  }

const handleMenuClick =
  ({ setTabMenuAnchor }) =>
  event => {
    setTabMenuAnchor(event.currentTarget)
  }

const handleOpenHistory =
  ({ setActiveTab, setTabMenuAnchor }) =>
  () => {
    setActiveTab(2)
    closeTabsMenu({ setTabMenuAnchor })()
  }

const PrintModal = withStyles({
  formInput: {
    marginTop: 20,
  },
  checkbox: {
    marginTop: 10,
    marginRight: 0,
  },
})(
  ({
    classes: css,
    field,
    setField,
    onChange,
    mode,
    vehicleOptions,
  }: {
    classes: Object,
    field: Function,
    setField: Function,
    onChange: Function,
    mode: Mode,
    vehicleOptions: Object[],
  }) => {
    const { t } = useTranslation()

    const handleChangeCheckbox = ({ target }) => {
      const { checked, name } = target

      setField(name, checked)
    }

    const handleChangeRadio = ({ target }) => {
      const { value, name } = target

      setField(name, value)

      if (value === PRINT_MODE.full) {
        if (vehicleOptions.length) setField('vehicleOptions', true)

        setField('requestSign', false)
      }

      if (value === PRINT_MODE.light) {
        setField('vehicleOptions', false)
      }

      setField('requestSign', false)
    }

    const printModeValue = field('printMode') || PRINT_MODE.light

    const isCheckboxOptionValue = field('vehicleOptions') ?? false

    return (
      <div style={{ width: 400, minWidth: 400, padding: '0 50px' }}>
        <Typography variant="body1" style={{ marginBottom: 10 }}>
          {t('quote.print.ownerNameQuestion')}
        </Typography>
        <TextField
          name="ownerName"
          label={t(mode === MODES.resval ? 'quote.customerLastname' : 'quote.ownerName')}
          value={field('ownerName')}
          InputLabelProps={{ shrink: true }}
          placeholder={`${t('globals.exampleShortColon')} Dupont`}
          className={css.formInput}
          {...{ onChange }}
        />
        <TextField
          name="ownerFirstname"
          label={t(mode === MODES.resval ? 'quote.customerFirstname' : 'quote.ownerFirstname')}
          value={field('ownerFirstname')}
          InputLabelProps={{ shrink: true }}
          placeholder={`${t('globals.exampleShortColon')} Jean`}
          className={css.formInput}
          {...{ onChange }}
        />
        {mode !== MODES.resval && (
          <TextField
            name="commitmentPrice"
            label={t('quote.print.commitmentPrice')}
            value={field('commitmentPrice')}
            InputLabelProps={{ shrink: true }}
            placeholder={t('quote.print.commitmentPrice')}
            autoComplete="off"
            className={css.formInput}
            {...{ onChange }}
          />
        )}

        <Divider style={{ margin: '20px 0 10px' }} />

        <RadioGroup value={printModeValue} name="printMode" row onChange={handleChangeRadio}>
          <FormControlLabel
            value={PRINT_MODE.light}
            control={<Radio color="secondary" />}
            label={t('quote.print.settings_light')}
          />
          <FormControlLabel
            value={PRINT_MODE.full}
            control={<Radio color="secondary" />}
            label={t('quote.print.settings_full')}
          />
        </RadioGroup>

        {printModeValue === PRINT_MODE.light && (
          <FormControlLabel
            control={
              <Checkbox color="primary" name="requestSign" onChange={handleChangeCheckbox} />
            }
            label={t('quote.print.requestSign')}
          />
        )}

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="vehicleOptions"
              checked={isCheckboxOptionValue}
              onChange={handleChangeCheckbox}
              disabled={vehicleOptions.length === 0}
            />
          }
          label={t('quote.print.vehicleOptions.title')}
        />
      </div>
    )
  },
)

const handlePrint =
  ({
    field,
    setField,
    onChange,
    routerHistory,
    id,
    handleAlertOpening,
    handleAlertClosing,
    vehicleOptions,
    make,
    model,
    plate,
    mode,
  }) =>
  () => {
    const askPrintParameters = new Promise((resolve, reject) => {
      handleAlertOpening()({
        title: (
          <div>
            {i18next.t('quote.print.request')}
            <Typography variant="caption" style={{ textTransform: 'lowercase' }}>
              <span style={{ textTransform: 'uppercase' }}>
                {i18next.t('quote.print.pleaseEnterInformation')[0]}
              </span>
              {i18next.t('quote.print.pleaseEnterInformation').substring(1)}
            </Typography>
          </div>
        ),
        content: <PrintModal {...{ field, setField, onChange, mode, vehicleOptions }} />,
        positiveLabel: i18next.t('globals.validate'),
        negativeLabel: i18next.t('globals.cancel'),
        handlePositiveClose: () => {
          resolve()
          handleAlertClosing()()
        },
        handleNegativeClose: () => {
          reject()
          handleAlertClosing()()
        },
      })
    })

    askPrintParameters
      .then(() => {
        routerHistory.push({
          pathname: printQuotePath(id),
          state: {
            options: {
              id,
              ownerName: field('ownerName'),
              ownerFirstname: field('ownerFirstname'),
              commitmentPrice: field('commitmentPrice'),
              isChecked: Boolean(field('requestSign')),
              vehicleOptions: Boolean(field('vehicleOptions')),
              mode: field('printMode') || PRINT_MODE.light,
              make,
              model,
              plate,
            },
          },
        })
      })
      .catch(() => {})
  }

const EmptyComponent = ({ children }: { children: any }) => <> {children} </>

type AllComments = {
  [key: string]: string,
}

const getCommentsParsed = (comments: { type: string, content: string }[]): AllComments => {
  const results = {}

  comments.forEach(comment => {
    results[comment.type] = comment.content
  })

  return results
}

const getPicturesFormatted = ({ pictures, translatePicturesCaption, type }): Object[] =>
  pictures
    ?.filter(doc => doc.type === type)
    .map(picture => ({
      id: picture.id,
      name: picture.filename,
      caption: translatePicturesCaption(picture.tags),
      src: picture.uri,
    }))

type QuotePageProps = {
  classes: Object,
  user?: Object,
  field: Function,
  onChange: Function,
  routerHistory: Object,
  dispatch: Function,
  isAutoriginActivated?: boolean,
  isRadarActivated?: boolean,
  rights: string[],
  deleteField: Function,
  fields: Object,
  setFields: Function,
  setField: Function,
  mode: Mode,
}

const getThreadType = (quotations?: Object[]): 'main' | 'enquirer' =>
  !quotations || !quotations?.length ? 'main' : 'enquirer'

const QuotePageJSX = ({
  classes: css,
  user,
  field,
  onChange,
  routerHistory,
  dispatch,
  isAutoriginActivated,
  isRadarActivated,
  rights,
  deleteField,
  fields,
  setFields,
  setField,
  mode,
}: QuotePageProps) => {
  const id = routerHistory.location.pathname.substring(
    routerHistory.location.pathname.lastIndexOf('/') + 1,
  )

  const { t, i18n } = useTranslation()
  const mediaQueryLandscape = window.matchMedia('(min-width: 960px)')
  const [indexedQuote, setIndexedQuote] = useState({})
  const [enquirerGroup, setEnquirerGroup] = useState({})
  const [status, setStatus] = useState('')
  const [enquirer, setEnquirer] = useState({})
  const [tabMenuAnchor, setTabMenuAnchor] = useState(null)
  const [activeTab, setActiveTab] = useState(0)
  const [comments, setComments] = useState<AllComments>({})
  const [isLandscape, setIsLandscape] = useState(mediaQueryLandscape.matches)
  const [printAlertOptions, setPrintAlertOptions] = useState({ isOpened: false })
  const [expiredStatusQuote, setExpiredStatusQuote] = useState(false)
  const [onAdminCrashedComment, setOnAdminCrashedComment] = useState(false)
  const [listGroupResults, setListGroupResults] = useState([])
  const [listUsersResults, setListUsersResults] = useState([])
  const [changedTags, setChangedTags] = useState(indexedQuote?.tags)
  const [typeSite, setTypeSite] = useState('')

  const {
    isLoadingMultiquotation,
    multiquotation,
    handlePagination,
    multiquotationResults,
    currentPage,
    rowsPerPage,
  } = useMultiquotation({ vin: indexedQuote?.vin, excludeId: id, mode })

  const [getComments, { loading: areCommentsLoading }] = useLazyQuery(getCommentsQuery)
  const dmg = indexedQuote?.damages
  const damages = {}
  let damagesPictures = []

  const translatePicturesCaption = tags =>
    tags
      .map(tag => {
        if (i18n.exists(`damage.${tag}`)) {
          return t(`damage.${tag}`)
        }
        if (i18n.exists(`damage.type.${tag}`)) {
          return t(`damage.type.${tag}`)
        }
        return t(`quote.picture.${tag}`)
      })
      .join(' — ')

  const pictures =
    id &&
    getPicturesFormatted({
      pictures: indexedQuote.documents,
      translatePicturesCaption,
      type: DOCUMENT_TYPE.PHOTO,
    })

  if (mode === MODES.standard_bike) {
    damagesPictures = getPicturesFormatted({
      pictures: indexedQuote.documents,
      translatePicturesCaption,
      type: DOCUMENT_TYPE.DMG,
    })
  }

  dmg &&
    dmg.forEach(damage => {
      damages[damage.zone] = { type: damage.type }
      damagesPictures = getPicturesFormatted({
        pictures: indexedQuote.documents,
        translatePicturesCaption,
        type: DOCUMENT_TYPE.DMG,
      })
    })

  const damageListReportTotal =
    indexedQuote.documents &&
    indexedQuote.documents.filter(document => document.type === DOCUMENT_TYPE.DMG_LIST_REPORT)
      .length

  const damageListReport =
    indexedQuote?.id &&
    indexedQuote.documents &&
    indexedQuote.documents
      .filter(document => document.type === DOCUMENT_TYPE.DMG_LIST_REPORT)
      .map((tech, i) => ({
        name: damageListReportTotal && `${i + 1}/${damageListReportTotal}`,
        src: tech.uri,
      }))

  const repairCostReportTotal =
    indexedQuote.documents &&
    indexedQuote.documents.filter(document => document.type === DOCUMENT_TYPE.REPAIR_COST_REPORT)
      .length

  const repairCostReport =
    indexedQuote?.id &&
    indexedQuote.documents &&
    indexedQuote.documents
      .filter(document => document.type === DOCUMENT_TYPE.REPAIR_COST_REPORT)
      .map((tech, i) => ({
        name: repairCostReportTotal && `${i + 1}/${repairCostReportTotal}`,
        src: tech.uri,
      }))

  const quoterPrice = getPrice(
    'quoter_marged',
    indexedQuote?.prices,
    user?.config?.pricing?.display,
    user?.rights,
  )
  const { quoter, pending_quoter: pendingQuoter } = getPrices(
    ['quoter', 'pending_quoter'],
    indexedQuote?.prices && indexedQuote?.prices,
  )

  const radarURL =
    indexedQuote.documents &&
    indexedQuote.documents.find(doc => doc.type === DOCUMENT_TYPE.RADAR)?.uri
  const radarEnquirerURL =
    indexedQuote.documents &&
    indexedQuote.documents.find(doc => doc.type === DOCUMENT_TYPE.RADAR_ENQUIRER)?.uri

  const accessModes = getAccessMode({ data: user })

  const handleAlertOpening = () => options => {
    const printModeValue = field('printMode') || PRINT_MODE.light
    const vehicleOptions = indexedQuote?.options ?? []

    if (printModeValue === PRINT_MODE.full && vehicleOptions.length) {
      setField('vehicleOptions', true)
    }

    if (printModeValue === PRINT_MODE.light) {
      setField('vehicleOptions', false)
    }

    setField('requestSign', false)

    setPrintAlertOptions({ isOpened: true, ...options })
  }

  const handleAlertClosing = () => () => {
    deleteField && deleteField(`${typeSite && typeSite}Site`)
    setPrintAlertOptions({ isOpened: false, ...printAlertOptions })
  }

  const [getQuoteInfos, { loading: isPageLoading }] = useLazyQuery(getQuoteAndCountQuery, {
    variables: { id },
    onCompleted: data => {
      const quoteData = data.get_indexed_quote

      if (accessModes.includes(quoteData.category)) {
        dispatch(setMode(quoteData.category))
      }

      setStatus(quoteData.status)
      setEnquirer(quoteData.enquirer)
      setEnquirerGroup(quoteData?.enquirerGroup)
      setIndexedQuote(quoteData)
      setExpiredStatusQuote(quoteData.expired)
      if (!routerHistory.location.state)
        routerHistory.push({
          state: {
            make: quoteData?.make,
            plate: quoteData?.plate,
          },
        })
    },
  })

  const { loading: isLoadingAutorigin, data: autoriginData } = useQuery(getQuoteAutoriginQuery, {
    variables: { quoteId: indexedQuote?.id },
    skip: indexedQuote?.id === undefined,
  })

  useEffect(() => {
    if (id) {
      getQuoteInfos().then(data => {
        const fetchComments = async () => {
          const respComments = await getComments({
            variables: {
              filters: {
                quote: id,
                threadType: getThreadType(data?.data?.get_indexed_quote?.quotations),
              },
            },
          })
          if (respComments.data.search_comment.results?.length > 0) {
            const commentsParsed = getCommentsParsed(respComments.data.search_comment.results ?? [])
            setComments(commentsParsed)

            if (commentsParsed.admin_crashed_status) {
              setOnAdminCrashedComment(true)
            }
          }
        }
        fetchComments()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  mediaQueryLandscape.addListener(e => {
    setIsLandscape(e.matches)
  })

  // Tab management
  const tabIndices = ['quotation', 'enquirer_comments', 'history']

  if (
    rights.find(right => right === 'view_tracking') &&
    (status === 'sent' || status === 'in_stock' || status === 'sold')
  ) {
    tabIndices.push('traceability')
  }

  if (
    rights.find(
      right =>
        right === 'update_expire_admin_quote' ||
        right === 'update_price_admin_quote' ||
        right === 'update_site_admin_quote' ||
        right === 'update_tags_admin_quote' ||
        right === 'move_admin_quote',
    ) &&
    status !== 'waiting'
  ) {
    tabIndices.push('modify')
  }

  const isEnabledMultiquotation = user?.config?.quote?.quote?.multiquotation?.enabled ?? false
  const hasIsOrgMultiquotedTag = indexedQuote?.tags?.includes(TAG_QUOTE_IS_ORG_MULTIQUOTED) ?? false
  const canViewMultiquotation =
    isEnabledMultiquotation &&
    hasIsOrgMultiquotedTag &&
    rights.includes('view_group_quote') &&
    ![
      QUOTE_STATUS_DRAFT,
      QUOTE_STATUS_CHANGES_REQUESTED,
      QUOTE_STATUS_PROCESSING,
      QUOTE_STATUS_ONHOLD_OPENING,
      QUOTE_STATUS_PENDING_PRICE,
      QUOTE_STATUS_WAITING,
    ].includes(status)

  if (canViewMultiquotation) {
    tabIndices.push('multiquotation')
  }

  // Query modify quote
  const [expirationQuote, { loading: expiredQuoteLoading }] = useMutation(adminExpiredQuoteApollo, {
    onCompleted: () => {
      setExpiredStatusQuote(false)
      dispatch(
        notify({
          title: t('quote.cancelExpiredQuoteNotification'),
          subTitle: t('quote.cancelExpiredQuoteNotificationStatus'),
          message: t('quote.cancelExpiredQuoteNotificationMessage', { plate: indexedQuote?.plate }),
          icon: <CheckCircle style={{ color: '#66DDAA' }} />,
          autoHideDuration: null,
        }),
      )
    },
  })

  const [onAdminTagsQuote, { loading: UpdateTagsQuoteLoading }] = useMutation(
    adminTagsQuoteMutation,
    {
      onCompleted: () => {
        setComments(prevComments => ({
          ...prevComments,
          admin_crashed_status: field('crashedComment'),
        }))
        deleteField('crashedComment')
        dispatch(
          notify({
            title: t('quote.modifyQuotationUpdateTagsTitle'),
            subTitle: t('quote.modifyQuotationNotificationStatus'),
            message: t('quote.modifyQuotationNotificationMessage', { plate: indexedQuote?.plate }),
            icon: <CheckCircle style={{ color: '#66DDAA' }} />,
            autoHideDuration: null,
          }),
        )
      },
    },
  )

  const [onAdminPriceQuote, { loading: isAdminPriceLoading }] = useMutation(
    adminPriceQuoteMutation,
    {
      onCompleted: () => {
        dispatch(
          notify({
            title: t('quote.modifyPriceQuoteTitle'),
            subTitle: t('quote.cancelExpiredQuoteNotificationStatus'),
            message: t('quote.modifyQuotationUpdatePriceNotificationMessage', {
              plate: indexedQuote?.plate,
            }),
            icon: <CheckCircle style={{ color: '#66DDAA' }} />,
            autoHideDuration: null,
          }),
        )
      },
    },
  )

  const [onAdminSiteQuote, { loading: isAdminSiteLoading }] = useMutation(adminSiteQuoteMutation, {
    onCompleted: () => {
      dispatch(
        notify({
          title:
            typeSite === 'withdrawal'
              ? t('quote.modifyQuotationUpdateSiteWithdrawalButton')
              : t('quote.modifyQuotationUpdateSiteBillingButton'),
          subTitle: t('quote.cancelExpiredQuoteNotificationStatus'),
          message:
            typeSite === 'withdrawal'
              ? t('quote.modifyQuotationUpdateSiteWithdrawalMessage', {
                  plate: indexedQuote?.plate,
                })
              : t('quote.modifyQuotationUpdateSiteBillingMessage', { plate: indexedQuote?.plate }),
          icon: <CheckCircle style={{ color: '#66DDAA' }} />,
          autoHideDuration: null,
        }),
      )
    },
  })

  const [fetchListSites, { loading: isLoadingListSites }] = useLazyQuery(listSitesQueryApollo, {
    onCompleted: data => {
      data &&
        data.list_sites?.results &&
        handleAlertOpening()({
          title:
            typeSite === 'withdrawal'
              ? t('quote.modifyQuotationUpdateSiteWithdrawalButton')
              : t('quote.modifyQuotationUpdateSiteBillingButton'),
          content: (
            <UpdateSiteQuoteModal
              withdrawalSite={indexedQuote?.withdrawalSite}
              billingSite={indexedQuote?.billingSite}
              field={field}
              onChange={onChange}
              typeSite={typeSite}
              data={data}
              enquirerGroup={enquirerGroup}
              isLoadingListSites={isLoadingListSites}
            />
          ),
          positiveLabel: t('quote.modifyQuotationUpdateSiteButtonValidation'),
          negativeLabel: t('globals.cancel'),
          handlePositiveClose: () => {
            onAdminSiteQuote({
              variables: {
                id,
                siteId: field(`${typeSite}Site`),
                typeSite,
              },
            })
            handleAlertClosing()()
          },
          handleNegativeClose: () => handleAlertClosing()(),
        })
    },
  })

  const [listSites, { loading: listSitesLoading }] = useLazyQuery(listSitesQueryApollo, {
    onCompleted: data => {
      data &&
        data.list_sites?.results &&
        handleAlertOpening()({
          title:
            typeSite === 'withdrawal'
              ? t('quote.modifyQuotationUpdateSiteWithdrawalButton')
              : t('quote.modifyQuotationUpdateSiteBillingButton'),
          content: (
            <UpdateSiteQuoteModal
              withdrawalSite={indexedQuote?.withdrawalSite}
              billingSite={indexedQuote?.billingSite}
              field={field}
              onChange={onChange}
              typeSite={typeSite}
              data={data}
              enquirerGroup={enquirerGroup}
              isLoadingListSites={isLoadingListSites}
              sitesList={data.list_sites.results}
              listSites={listSites}
              listSitesLoading={listSitesLoading}
            />
          ),
          positiveLabel: t('quote.modifyQuotationUpdateSiteButtonValidation'),
          negativeLabel: t('globals.cancel'),
          handlePositiveClose: () => {
            onAdminSiteQuote({
              variables: {
                id,
                siteId: field(`${typeSite}Site`),
                typeSite,
              },
            })
            handleAlertClosing()()
          },
          handleNegativeClose: () => handleAlertClosing()(),
        })
    },
  })

  const [listUsers, { loading: listUsersLoading }] = useLazyQuery(listUsersQuery, {
    onCompleted: data => {
      setListUsersResults(data.list_users.results)
    },
  })

  const onSearchUserHandler = async (pattern, listGroupId) => {
    const resp = await listUsers({
      variables: {
        filters: {
          groups: [listGroupId.value],
          asOrganization: true,
          usernameLike: pattern,
        },
      },
    })
    let options
    if (resp.data.list_users && resp.data.list_users.results) {
      options = resp.data.list_users.results.map(item => ({
        label: `${item.firstname} ${item.lastname}`,
        value: item.id,
      }))
    }
    return options
  }

  const [listGroup] = useLazyQuery(listGroupQuery, {
    onCompleted: data => {
      setListGroupResults(data?.list_groups?.results)
    },
  })

  const onSearchHandler = async pattern => {
    const resp = await listGroup({ variables: { nameLike: pattern } })
    let options
    if (resp.data.list_groups && resp.data.list_groups.results) {
      options = resp.data.list_groups.results.map(item => ({
        label: item.rootGroup?.name,
        value: item.id,
        options: [
          {
            value: item.id,
            label: item.name,
          },
        ],
      }))
    }
    return options
  }

  const [onAdminMoveQuote, { loading: isAdminMoveQuoteLoading }] = useMutation(
    adminMoveQuoteMutation,
    {
      onCompleted: () => {
        dispatch(
          notify({
            title: t('quote.modifyQuotationMoveAdminQuoteTitle'),
            subTitle: t('quote.cancelExpiredQuoteNotificationStatus'),
            message: t('quote.modifyQuotationMoveAdminQuoteMessage', {
              plate: indexedQuote?.plate,
              enquiredGroupName: enquirerGroup?.name,
            }),
            icon: <CheckCircle style={{ color: '#66DDAA' }} />,
            autoHideDuration: null,
          }),
        )
      },
    },
  )

  const getCommentsTab = () => (page, type) =>
    getComments({
      variables: {
        filters: { quote: id, threadType: type },
        page: page || 1,
      },
    }).then(r => r.data)

  const brandProps = {
    make: indexedQuote?.make,
    model: indexedQuote?.model,
    version: indexedQuote?.version,
    trimLevel: mode !== MODES.standard_bike ? indexedQuote?.trimLevel ?? '--' : null,
    date: {
      startModelPhase: indexedQuote?.startModelPhase
        ? `${moment(indexedQuote?.startModelPhase?.date).format('MM/YYYY')} - `
        : '',
      endModelPhase: indexedQuote?.endModelPhase
        ? moment(indexedQuote?.endModelPhase?.date).format('MM/YYYY')
        : '',
      startVersionPhase: indexedQuote?.startVersionPhase
        ? `${moment(indexedQuote?.startVersionPhase?.date).format('MM/YYYY')} - `
        : '',
      endVersionPhase: indexedQuote?.endVersionPhase
        ? moment(indexedQuote?.endVersionPhase?.date).format('MM/YYYY')
        : '',
    },
  }

  const isUpdatedFleetStatus = status === QUOTE_STATUS_UPDATED_FLEET

  const vehicleInformationProps = {
    energy: indexedQuote?.energy,
    body: indexedQuote?.body,
    color: indexedQuote?.color,
    currentKm: indexedQuote?.currentKm,
    deliveryKm: indexedQuote?.deliveryKm,
    deliveryHour: indexedQuote?.deliveryHour,
    type: mode === MODES.standard_bike ? indexedQuote?.regType : indexedQuote?.type,
    kwPower: indexedQuote?.kwPower,
    gearbox: indexedQuote?.gearbox,
    euroStandard: indexedQuote?.euroStandard,
    firstSellPrice: indexedQuote?.firstSellPrice,
    catalogId: indexedQuote?.catalogId,
    vin: indexedQuote?.vin,
    doors: indexedQuote?.doors,
    seats: indexedQuote?.seats,
    hpPower: indexedQuote?.hpPower,
    co2: indexedQuote?.co2,
    displacement: indexedQuote?.displacement,
    releaseDate: indexedQuote?.releaseDate?.date,
    deliveryDate: indexedQuote?.deliveryDate?.date,
    firstRegDate: indexedQuote?.firstRegDate?.date,
    lastRegDate: indexedQuote?.lastRegDate?.date,
    taxPower:
      indexedQuote?.identificationCountry && indexedQuote?.identificationCountry === 'fr'
        ? indexedQuote?.taxPower
        : null,
    kmToStart: isUpdatedFleetStatus
      ? indexedQuote?.pendingUpdate?.contractKmStart
      : indexedQuote?.contractKmStart,
    kmToGo: isUpdatedFleetStatus
      ? indexedQuote?.pendingUpdate?.contractKm
      : indexedQuote?.contractKm,
    contractDuration: isUpdatedFleetStatus
      ? indexedQuote?.pendingUpdate?.contractDuration
      : indexedQuote?.contractDuration,
    contractDateStart: indexedQuote?.contractDateStart?.date,
    exclude: getExcludedElements(indexedQuote),
    registrationDocument:
      indexedQuote?.id &&
      indexedQuote?.documents
        .filter(document => document.type === 'REG_CARD')
        .map((reg, index) => ({
          name: `${index + 1}/${
            indexedQuote?.documents.filter(document => document.type === 'REG_CARD').length
          }`,
          src: reg.uri,
        })),
    technicalControl:
      indexedQuote?.id &&
      indexedQuote?.documents
        .filter(document => document.type === 'TECH_CHECK')
        .map((tech, i) => ({
          name: `${i + 1}/${
            indexedQuote?.documents.filter(document => document.type === 'TECH_CHECK').length
          }`,
          src: tech.uri,
        })),
    batteryNumber: indexedQuote?.vehicle?.batteryNumber ?? null,
    electricEngineNumber: indexedQuote?.vehicle?.electricEngineNumber ?? null,
    batteryCapacityKwh: indexedQuote?.vehicle?.batteryCapacityKwh ?? null,
    batteryWarranty: indexedQuote?.vehicle?.batteryWarranty ?? null,
  }

  const quoteActionsPanelProps = {
    id,
    status,
    quoterPrice,
    quoterPriceNotMarged:
      mode === MODES.resval && indexedQuote?.status === QUOTE_STATUS_UPDATED_FLEET
        ? pendingQuoter
        : quoter,
    plate: indexedQuote?.plate,
    askCorrectionComment: comments?.ask_correction ?? '',
    expired: indexedQuote?.expired,
    archived: indexedQuote?.archived,
    prices: indexedQuote?.prices,
    withdrawalSite: indexedQuote?.withdrawalSite,
    billingSite: indexedQuote?.billingSite,
    enquirerGroup,
    pricingComment: comments?.pricing ?? null,
    setStatus,
    contractDateStart: indexedQuote?.contractDateStart?.date,
    pendingUpdateExpired: indexedQuote?.pendingUpdate?.expired,
    isPenddingUpdate: !!indexedQuote?.pendingUpdate,
    hasAlreadyBeenAppreciated: hasIsOrgMultiquotedTag && isEnabledMultiquotation,
  }

  return (
    <>
      {isPageLoading ? (
        <LoadingPage noDelay />
      ) : (
        <div className={css.wrapperPage}>
          <Alert {...printAlertOptions} />
          {((isLandscape && activeTab !== tabIndices.indexOf('history')) || !isLandscape) && (
            <div className={css.tabsWithSubtitle}>
              <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)}>
                <Tab label={t('quote.quotation')} icon={<DetailsIcon />} />

                <Tab
                  label={t('quote.comments')}
                  icon={<CommentIcon style={{ transform: 'scale(-1, 1)' }} />}
                />
                <Tab
                  className={css.hiddenOnDesktop}
                  label={t('quote.history')}
                  icon={<HistoryIcon />}
                />
                {tabIndices.indexOf('multiquotation') !== -1 && (
                  <Tab
                    className={
                      activeTab === tabIndices.indexOf('multiquotation') && multiquotationResults
                        ? `${css.activeTab} ${css.multiquotationTab}`
                        : `${css.tab} ${css.multiquotationTabActive}`
                    }
                    label={
                      <MuiBadge
                        style={{ paddingRight: 15 }}
                        badgeContent={multiquotationResults}
                        classes={
                          activeTab !== tabIndices.indexOf('multiquotation')
                            ? { badge: css.multiquotationBadge }
                            : { badge: css.multiquotationBadgeActive }
                        }
                      >
                        {t('quote.multiquotation')}
                      </MuiBadge>
                    }
                    disabled={multiquotationResults === 0}
                    icon={
                      <MultiquotationIcon
                        className={
                          activeTab === tabIndices.indexOf('quotation') && multiquotationResults
                            ? css.multiquotationIconActive
                            : css.multiquotationIcon
                        }
                      />
                    }
                  />
                )}
                {tabIndices.indexOf('traceability') !== -1 && (
                  <Tab label={t('quote.traceability.traceability')} icon={<RoomIcon />} />
                )}
                {tabIndices.indexOf('modify') !== -1 && (
                  <Tab
                    className={classnames(
                      activeTab === tabIndices.indexOf('modify') ? css.activeTab : css.tab,
                    )}
                    label={t('quote.modifyQuotation')}
                    icon={<Edit />}
                  />
                )}
                <EmptyComponent>
                  <Hidden smDown>
                    <div className={css.tabMenuContainer}>
                      <IconButton
                        style={{ borderRadius: 0 }}
                        onClick={handlePrint({
                          printAlertOptions,
                          setPrintAlertOptions,
                          field,
                          setField,
                          onChange,
                          routerHistory,
                          id,
                          handleAlertClosing,
                          handleAlertOpening,
                          vehicleOptions: indexedQuote?.options ?? [],
                          plate: indexedQuote?.plate,
                          make: indexedQuote?.make,
                          model: indexedQuote?.model,
                          mode,
                        })}
                      >
                        <PrintIcon classes={{ root: css.tabsIcon }} />
                      </IconButton>
                      <IconButton
                        aria-owns={tabMenuAnchor ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={handleMenuClick({ setTabMenuAnchor })}
                        style={{ borderRadius: 0 }}
                      >
                        <TabsMenuIcon classes={{ root: css.tabsIcon }} />
                      </IconButton>
                      <Menu
                        id="simple-menu"
                        anchorEl={tabMenuAnchor}
                        open={Boolean(tabMenuAnchor)}
                        onClose={closeTabsMenu({ setTabMenuAnchor })}
                      >
                        <MenuItem
                          style={{ margin: '0.5rem', background: 'transparent' }}
                          onClick={handleOpenHistory({ setActiveTab, setTabMenuAnchor })}
                        >
                          <HistoryIcon style={{ marginRight: 10 }} />{' '}
                          <Typography component="span" variant="body1">
                            {t('quote.history')}
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  </Hidden>
                </EmptyComponent>
              </Tabs>
              <Hidden mdUp>
                <div className={css.subtitle}>
                  <Typography variant="h2" classes={{ h2: css.subtitleText }}>
                    {indexedQuote?.make || '-'} {indexedQuote?.model || '-'}
                    <br />
                    {status && (
                      <Typography
                        component="span"
                        variant="h3"
                        classes={{ h3: css.subtitleStatus }}
                      >
                        {t(`globals.allStatus.${status}`)}
                      </Typography>
                    )}
                  </Typography>
                </div>
              </Hidden>
            </div>
          )}
          <div className={css.tabContentContainer}>
            {activeTab === tabIndices.indexOf('quotation') && (
              <Quotation
                {...{ id, status, setStatus, user, pictures, damagesPictures }}
                isExpired={indexedQuote?.expired}
                isArchived={indexedQuote?.archived}
                plate={{
                  plate: indexedQuote?.plate,
                  country: indexedQuote?.identificationCountry
                    ? indexedQuote.identificationCountry.toUpperCase()
                    : null,
                }}
                applicantInformations={{
                  enquirer,
                  enquirerGroup,
                  contact: indexedQuote?.contact,
                  reference: indexedQuote?.reference,
                }}
                brand={brandProps}
                tags={indexedQuote?.tags ?? []}
                vehicleInformation={vehicleInformationProps}
                damages={{
                  ...damages,
                  schema: getNameSchema(indexedQuote?.type, indexedQuote?.doors),
                }}
                btnInfo={{
                  appName: 'provider',
                  radarURL,
                  radarEnquirerURL,
                  isRadarActivated,
                  isAutoriginActivated,
                  autoriginURL: autoriginData?.get_quote_autorigin?.uri ?? null,
                  isDisabledAutorigin: !autoriginData?.get_quote_autorigin?.uri,
                  isLoadingAutorigin: isPageLoading || isLoadingAutorigin,
                  options: (indexedQuote?.options ?? []).map(option => ({
                    name: option.name,
                    price: `${option.price} €`,
                  })),
                }}
                vehicleConditon={{
                  mechanicalComment: comments?.mechanical_status ?? null,
                  bodyworkComment: comments?.bodywork_status ?? null,
                  crashedComment:
                    comments?.admin_crashed_status ?? comments?.crashed_status ?? null,
                  generalComment: comments?.general_status ?? null,
                  totalCostsComment: comments?.total_costs ?? null,
                  damageCostsMismatchComment: comments?.dmg_and_costs_mismatch ?? null,
                  damageCostsMatchComment: comments?.dmg_and_costs_match ?? null,
                  contactDetailsComment: comments?.contact_details ?? null,
                }}
                damagesInfos={{
                  damages,
                  prices: indexedQuote?.prices,
                  damageListReport,
                  repairCostReport,
                }}
                quoteActionsPanel={quoteActionsPanelProps}
                prices={indexedQuote?.prices}
                spotEnquirerRotation={indexedQuote?.spotEnquirerRotation}
                spotRotation={indexedQuote?.spotRotation}
                quoterPrice={
                  mode === MODES.resval && indexedQuote?.status === QUOTE_STATUS_UPDATED_FLEET
                    ? pendingQuoter
                    : quoter
                }
                createdAt={indexedQuote?.createdAt}
                updatedAt={indexedQuote?.updatedAt}
                source={indexedQuote?.source}
                mode={mode}
              />
            )}
          </div>

          {activeTab === tabIndices.indexOf('multiquotation') && (
            <div style={{ padding: '60px', maxWidth: '100%' }}>
              <Multiquotation
                multiquotation={multiquotation}
                currentData={indexedQuote}
                multiquotationResults={multiquotationResults}
                isLoadingMultiquotation={isLoadingMultiquotation}
                handlePagination={handlePagination}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
              />
            </div>
          )}

          <div>
            {activeTab === tabIndices.indexOf('enquirer_comments') && (
              <div className={css.tabContentContainer} style={{ padding: '60px 0 0 60px' }}>
                <Comments
                  {...{ id, areCommentsLoading }}
                  getComments={getCommentsTab()}
                  owner={{
                    id: user?.id,
                    fullname: user?.fullname,
                  }}
                  type="enquirer"
                  threadType={getThreadType(indexedQuote?.quotations)}
                />
              </div>
            )}
          </div>

          {activeTab === tabIndices.indexOf('history') && (
            <div style={{ padding: '60px 0 0 60px' }}>
              <Hidden smDown>
                <IconButton className={css.backButton} onClick={() => setActiveTab(0)}>
                  <NavigateBefore className={css.backIcon} />
                  <Typography variant="caption">{t('quote.backToVehicleInformation')}</Typography>
                </IconButton>
              </Hidden>
              <HistoryLog
                id={id}
                className={classnames(css.tabContentContainer, css.historyContainer)}
              />
            </div>
          )}
          {activeTab === tabIndices.indexOf('traceability') && (
            <div className={css.tabContentContainer} style={{ padding: '60px 0 0 60px' }}>
              <Traceability
                {...{
                  auctionGroup: indexedQuote?.auctionGroup,
                  tracking: indexedQuote?.tracking,
                  withdrawalSite: indexedQuote?.withdrawalSite,
                  billingSite: indexedQuote?.billingSite,
                  assignedAt: indexedQuote?.assignedAt,
                }}
                soldPrice={
                  indexedQuote.prices && indexedQuote.prices.find(price => price.type === 'sell')
                }
              />
            </div>
          )}
          {activeTab === tabIndices.indexOf('modify') && (
            <div style={{ padding: '60px 0 0 60px' }}>
              <ModifyQuote
                {...{
                  id,
                  statusSendQuoteToAuctioneer: status,
                  plate: indexedQuote?.indexedQuote,
                  rights,
                  expiredStatusQuote,
                  commitmentPrice: quoterPrice,
                  crashedComment:
                    comments?.admin_crashed_status ?? comments?.crashed_status ?? null,
                  onAdminCrashedComment,
                  quotations: indexedQuote?.quotations,
                  withdrawalSite: indexedQuote?.withdrawalSite,
                  billingSite: indexedQuote?.billingSite,
                  enquirerGroup,
                  expirationQuote,
                  expiredQuoteLoading,
                  onAdminTagsQuote,
                  UpdateTagsQuoteLoading,
                  tagConfig: {
                    TAG_IS_DAMAGED,
                    TAG_HAS_VAT,
                    TAG_IS_BROKEN,
                    TAG_NEEDS_TIMING_BELT,
                    TAG_HAS_SERVICE_RECORD,
                    TAG_HAS_NO_VAT,
                    TAG_IS_FOREIGN,
                    TAG_PREVIOUS_AMBULANCE,
                    TAG_PREVIOUS_TAXI,
                    TAG_PREVIOUS_DRIVING_SCHOOL,
                    TAG_PREVIOUS_LSR_LTR,
                    TAG_IS_TESTED,
                    TAG_IS_NOT_TESTED,
                    TAG_STATE_BAD,
                    TAG_STATE_AVERAGE,
                    TAG_STATE_GOOD,
                    TAG_STATE_VERY_GOOD,
                  },
                  changedTags,
                  setChangedTags,
                  field,
                  fields,
                  setFields,
                  onChange,
                  onAdminPriceQuote,
                  isAdminPriceLoading,
                  alertOptions: printAlertOptions,
                  setTypeSite,
                  isAdminSiteLoading,
                  listSites: fetchListSites,
                  listSitesLoading: isLoadingListSites,
                  questions,
                  listGroup,
                  listGroupResults,
                  setField,
                  listUsers,
                  listUsersLoading,
                  listUsersResults,
                  onSearchHandler,
                  onSearchUserHandler,
                  deleteField,
                  enquirer: enquirer?.fullname,
                  onAdminMoveQuote,
                  isAdminMoveQuoteLoading,
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

const QuotePageCmp = withStyles(theme => {
  const baseStyles = {
    lightText: { color: '#777' },
    carScheme: { maxWidth: '100%' },
    tabContentContainer: {
      paddingTop: 100,
      [theme.breakpoints.up('md')]: {
        paddingTop: 30,
      },
    },
    subtitle: {
      background: theme.palette.secondary.dark,
      color: 'white',
      width: '100%',
    },
    tabsWithSubtitle: {
      position: 'fixed',
      width: '100%',
      top: 64,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      transition: 'transform 0.2s ease-in-out',
    },
    subtitleText: { color: 'white', padding: '0.7rem 0', textAlign: 'center' },
    subtitleStatus: { color: 'white', marginTop: '0.3rem' },
    commentsBloc: {
      marginLeft: '0.5rem',
      paddingLeft: '0.8rem',
      borderLeft: `3px solid ${theme.palette.secondary.dark}`,
    },
    tabsIcon: {
      color: '#333',
      fontSize: '1.8rem',
    },
    tabMenuContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      flexGrow: 1,
      marginRight: 20,
    },
    hiddenOnDesktop: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    backIcon: {
      marginRight: '1rem',
      background: theme.palette.secondary.light,
      borderRadius: '50%',
      width: 30,
      height: 30,
      color: '#FFFFFF',
    },
    backButton: {
      position: 'absolute',
      top: 70,
      left: 50,
      marginTop: 10,
      borderRadius: 0,
      background: 'transparent !important',
    },
    brandTitle: {
      [theme.breakpoints.up('md')]: {
        marginLeft: 4,
        marginBottom: 10,
      },
    },
    vhInfos: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem !important',
      },
    },
    wrapperPage: {
      maxWidth: 1920,
    },
    multiQuotationIcon: {
      fill: '#000000',
      width: 24,
      height: 24,
    },
    multiQuotationIconActive: {
      width: 24,
      height: 24,
    },
    multiquotationTabActive: {
      extend: 'tab',
      background: '#DC241F',
      color: '#FFFFFF',
    },
    multiquotationTab: {
      extend: 'tab',
    },
    multiquotationBadge: {
      backgroundColor: 'white',
      color: theme.palette.error.main,
      fontSize: '0.7rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    multiquotationBadgeActive: {
      backgroundColor: theme.palette.error.main,
      fontSize: '0.7rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#FFFFFF',
    },
    multiquotationIconActive: { fill: '#FFFFFF', width: 24, height: 24 },
    multiquotationIcon: { width: 24, height: 24 },
  }
  for (let i = 1; i < 7; i += 1) {
    baseStyles[`order${i}`] = {
      [theme.breakpoints.down('sm')]: {
        order: i,
        marginTop: '2rem',
      },
    }
  }
  return baseStyles
})(QuotePageJSX)

const QuotePage: any = compose(
  withRedux(({ user, router }) => {
    const { currentMode } = getModesData(user, router.location.pathname)

    return {
      user: user.data,
      rights: user.data.rights,
      isAutoriginActivated: user?.data?.config?.ws?.autorigin?.activated,
      isRadarActivated:
        currentMode !== MODES.standard_bike && user?.data?.config?.ws?.radar?.activated,
      mode: currentMode,
    }
  }),
  withFields(),
)(QuotePageCmp)

export default QuotePage
