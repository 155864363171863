// @flow

import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import Progress from '@material-ui/core/CircularProgress'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import withStyles from '@material-ui/core/styles/withStyles'
import Pagination, {
  defaultNbElementByPage,
  defaultPage,
} from '@autodisol/ads-js/components/Pagination'
import Card from '@autodisol/ads-js/components/Card'
import moment from 'moment'
import swit from 'sharyn/util/swit'
import { connect as withRedux } from 'react-redux'

import { searchQuoteLogQuery } from 'quote/quote-queries'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const updateResults =
  ({ id, setResults, searchQuoteLog, setCount }) =>
  async (page, nbElementByPage) => {
    const resp = await searchQuoteLog({
      variables: {
        id,
        nbElementByPage: nbElementByPage || defaultNbElementByPage,
        page: page || defaultPage,
      },
    })

    setResults(resp.data.search_quotelog?.results)
    setCount(resp.data.search_quotelog?.nbResults)
  }

const HistoryLogJSX = ({
  classes: css,
  className,
  page,
  nbElementByPage,
  id,
  style,
}: {
  classes: Object,
  className?: string,
  page?: number,
  nbElementByPage?: number,
  id?: string,
  style?: Object,
}) => {
  const { t } = useTranslation()

  const [results, setResults] = useState([])
  const [count, setCount] = useState(0)

  const [searchQuoteLog, { loading: isPaginateLoading }] = useLazyQuery(searchQuoteLogQuery)

  useEffect(() => {
    updateResults({ id, setResults, searchQuoteLog, setCount })(page, nbElementByPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid spacing={16} container {...{ className, style }}>
      <Hidden smDown>
        <Table padding="dense">
          <TableHead>
            <TableRow>
              <TableCell>{t('quote.date')}</TableCell>
              <TableCell>
                {t('quote.user')} ({t('quote.company')})
              </TableCell>
              <TableCell>{t('quote.description')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isPaginateLoading ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <div className={css.loadingPagination}>
                    <Progress thickness={4} size={30} />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              results.map(historyLog => {
                const label = swit(
                  historyLog.action,
                  [
                    ['update_quote', t('quote.updated')],
                    ['quotation_quote', t('quote.affected')],
                    ['create_quote', t('quote.created')],
                    ['pricing_quote', t('quote.priceAssigned')],
                  ],
                  t('quote.noInformation'),
                )
                return (
                  <TableRow key={historyLog.id}>
                    <TableCell>
                      <Typography variant="body2">
                        {(() => {
                          const date = moment(historyLog?.loggedAt?.date).format('LLLL')
                          return `${date[0].toUpperCase()}${date.substring(1)}`
                        })()}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {historyLog?.user ? (
                        <Typography variant="body2">
                          {historyLog?.user?.fullname} ({historyLog?.user?.currentGroup})
                        </Typography>
                      ) : (
                        t('quote.noInformation')
                      )}
                    </TableCell>
                    <TableCell>{label}</TableCell>
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </Table>
      </Hidden>
      <Hidden mdUp>
        <div
          style={{
            margin: '20px 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {isPaginateLoading ? (
            <Progress thickness={4} size={30} />
          ) : (
            results.map(historyLog => {
              const label = swit(
                historyLog.action,
                [
                  ['update_quote', t('quote.updated')],
                  ['quotation_quote', t('quote.affected')],
                  ['create_quote', t('quote.created')],
                  ['pricing_quote', t('quote.priceAssigned')],
                ],
                t('quote.noInformation'),
              )
              return (
                <Card key={historyLog.id} style={{ marginTop: '0.5rem', width: '100%' }}>
                  <div>
                    <Typography variant="body2">
                      {(() => {
                        const date = moment(historyLog?.loggedAt?.date).format('LLLL')
                        return `${date[0].toUpperCase()}${date.substring(1)}`
                      })()}
                    </Typography>
                    {historyLog?.user ? (
                      <Typography variant="body2">
                        {historyLog?.user?.fullname} ({historyLog?.user?.currentGroup})
                      </Typography>
                    ) : (
                      t('quote.noInformation')
                    )}
                    <Typography variant="body1">{label}</Typography>
                  </div>
                </Card>
              )
            })
          )}
        </div>
      </Hidden>
      <Pagination
        {...{ count }}
        updateResults={updateResults({ id, setResults, searchQuoteLog, setCount })}
      />
    </Grid>
  )
}

const HistoryLogCmp = withStyles({
  loadingPagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
  },
})(HistoryLogJSX)

const HistoryLog: any = withRedux()(HistoryLogCmp)

export default HistoryLog
